import styled from 'styled-components';

export const LinkWrapper = styled.a`
  border-radius: 0.86rem;
  box-shadow: 0px 0px 4px rgba(89, 115, 114, 0.16);
  height: 2rem;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.whiteColor};
  display: flex;
  float: left;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
  margin: 10px 10px 0 0;
  @media (max-width: 768px) {
    height: 2rem;
    padding: 0 1rem;
  }

  &:hover {
    box-shadow: 0 0 0 0rem rgb(16 168 35 / 50%);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
`;

export const Title = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => props.theme.blackColor};
  margin: 0 0 0 0;
`;

export const JobsAmount = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: rgba(6, 14, 17, 0.7);
  margin: 0;
`;
