import { ICategory } from '@/blocks/CategoriesBlock/CategoriesBlock';
import { LinkWrapper, Title, ImageWrapper } from './CategoryCard.styles';
import Link from 'next/link';

const CategoryCard = ({ title, value, image }: ICategory) => {
  return (
    <Link href={`/${value}`} passHref>
      <LinkWrapper>
        <ImageWrapper>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={image}
            alt={value}
            width="18px"
            height="18px"
            loading="lazy"
          />
        </ImageWrapper>
        <div>{title && <Title>{title}</Title>}</div>
      </LinkWrapper>
    </Link>
  );
};

export default CategoryCard;
