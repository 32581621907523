import {
  Container,
  StyledDiv,
  CategoriesContainer,
} from './CategoriesBlock.styles';
import CategoryCard from '@/components/CategoryCard/CategoryCard';

export interface ICategory {
  title: string;
  value: string;
  image: string;
}

interface Props {
  categories: ICategory[];
  resetPage: () => void;
}

const CategoriesBlock = ({ categories }: Props) => {
  return (
    <Container>
      <StyledDiv>
        <CategoriesContainer>
          {categories?.length &&
            categories.map((el) => (
              <CategoryCard
                key={el.title}
                title={el.title}
                image={el.image}
                value={el.value}
              />
            ))}
        </CategoriesContainer>
      </StyledDiv>
    </Container>
  );
};

export default CategoriesBlock;
